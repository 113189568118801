<template>
  <div>Firewall</div>
</template>
<script setup>
import { useQmoduleBase } from "~/composables/useQmoduleBase";
import { useQmoduleProps } from "~/composables/useQmoduleProps";

const props = defineProps(useQmoduleProps());
const qmoduleBase = useQmoduleBase(props);
console.log("Firewall", qmoduleBase);
</script>
